import currencyReducer from "./currencyReducer";
import productReducer from "./productReducer";
import cartReducer from "./cartReducer";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import userReducer from "./userReducer";
import miscReducer from "./miscReducer";
import categoryReducer from "./categoryReducer";
import singleProductReducer from "./singleProductReducer";
import relatedProductReducer from "./relatedProductReducer";
import userCartReducer from "./userCartReducer";
import userWishlistReducer from "./userWishlistReducer";
import adminAuthReducer from "./adminAuthReducer";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import configReducer from "./configReducer";
import bannerReducer from "./bannerReducer";
import logoReducer from "./logoReducer";
import themeReducer from "./themeReducer";

const appReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  currencyData: currencyReducer,
  productData: productReducer,
  cartData: cartReducer,
  wishlistData: wishlistReducer,
  compareData: compareReducer,
  user: userReducer,
  misc: miscReducer,
  categories: categoryReducer,
  product: singleProductReducer,
  related: relatedProductReducer,
  userCart: userCartReducer,
  userWishlist: userWishlistReducer,
  admin: adminAuthReducer,
  config: configReducer,
  banners: bannerReducer,
  logo: logoReducer,
  theme: themeReducer,
});
/*const rootReducer =(state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'LOGOUT') {
   state=undefined
  }

  return appReducer(state, action);
}*/

export default appReducer;
