import PropTypes from "prop-types";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../../redux/actions/userActions";

const MobileNavMenu = ({ isLoggedIn, user, dispatch }) => {
  const history = useHistory();
  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      <ul>
        {isLoggedIn === false ? (
          <>
            <li>
              <Link
                to={{
                  pathname: process.env.PUBLIC_URL + "/login-register",
                  state: { key: "login" },
                }}
              >
                Login
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: process.env.PUBLIC_URL + "/login-register",
                  state: { key: "register" },
                }}
              >
                Register
              </Link>
            </li>
          </>
        ) : (
          <>
            <li>
              <Link to={process.env.PUBLIC_URL + "/my-account"}>
                My Account
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/my-orders"}>
                My Orders
              </Link>
            </li>
          </>
        )}
        <li className="menu-item-has-children">
          <Link to={process.env.PUBLIC_URL + "/shop"}>Shop</Link>
          {/*}  <ul className='sub-menu'>
            <li className='menu-item-has-children'>
              <Link to={process.env.PUBLIC_URL + '/shop-grid-standard'}>
                {strings['shop_layout']}
              </Link>
              <ul className='sub-menu'>
                <li>
                  <Link to={process.env.PUBLIC_URL + '/shop-grid-standard'}>
                    {strings['shop_grid_standard']}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + '/shop-grid-filter'}>
                    {strings['shop_grid_filter']}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + '/shop-grid-two-column'}>
                    {strings['shop_grid_two_column']}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + '/shop-grid-no-sidebar'}>
                    {strings['shop_grid_no_sidebar']}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + '/shop-grid-full-width'}>
                    {strings['shop_grid_full_width']}
                  </Link>
                </li>
                <li>
                  <Link
                    to={process.env.PUBLIC_URL + '/shop-grid-right-sidebar'}
                  >
                    {strings['shop_grid_right_sidebar']}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + '/shop'}>
                    {strings['shop_list_standard']}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + '/shop-list-full-width'}>
                    {strings['shop_list_full_width']}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + '/shop-list-two-column'}>
                    {strings['shop_list_two_column']}
                  </Link>
                </li>
              </ul>
            </li>
            <li className='menu-item-has-children'>
              <Link to={process.env.PUBLIC_URL + '/product/1'}>
                {strings['product_details']}
              </Link>
              <ul className='sub-menu'>
                <li>
                  <Link to={process.env.PUBLIC_URL + '/product/1'}>
                    {strings['product_tab_bottom']}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + '/product-tab-left/1'}>
                    {strings['product_tab_left']}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + '/product-tab-right/1'}>
                    {strings['product_tab_right']}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + '/product-sticky/1'}>
                    {strings['product_sticky']}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + '/product-slider/1'}>
                    {strings['product_slider']}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + '/product-fixed-image/1'}>
                    {strings['product_fixed_image']}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + '/product/1'}>
                    {strings['product_simple']}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + '/product/1'}>
                    {strings['product_variation']}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + '/product/1'}>
                    {strings['product_affiliate']}
                  </Link>
                </li>
              </ul>
            </li>
          </ul>*/}
        </li>

        {/*}<li>
          <Link to={process.env.PUBLIC_URL + '/shop'}>
            {strings['collection']}
          </Link>
        </li>*/}

        {/*}  <li className='menu-item-has-children'>
          <Link to={process.env.PUBLIC_URL + '/'}>{strings['pages']}</Link>
          <ul className='sub-menu'>
            <li>
              <Link to={process.env.PUBLIC_URL + '/cart'}>
                {strings['cart']}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + '/checkout'}>
                {strings['checkout']}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + '/wishlist'}>
                {strings['wishlist']}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + '/compare'}>
                {strings['compare']}
              </Link>
            </li>
            {/*}<li>
              <Link to={process.env.PUBLIC_URL + '/my-account'}>
                {strings['my_account']}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + '/login-register'}>
                {strings['login_register']}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + '/about'}>
                {strings['about_us']}
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + '/contact'}>
                {strings['contact_us']}
              </Link>
            </li>
            {/*}<li>
              <Link to={process.env.PUBLIC_URL + '/not-found'}>
                {strings['404_page']}
              </Link>
            </li>
          </ul>
        </li>*/}
        <li>
          <Link to={process.env.PUBLIC_URL + "/about"}>About Us</Link>
        </li>

        <li>
          <Link to={process.env.PUBLIC_URL + "/blog"}>Blog</Link>
        </li>

        <li>
          <Link to={process.env.PUBLIC_URL + "/contact"}>Contact Us</Link>
        </li>
        {isLoggedIn === true && (
          <li>
            <Link
              onClick={async () => {
                await dispatch(logout());
                await history.push("/");
                await window.location.reload();
              }}
            >
              Logout
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

MobileNavMenu.propTypes = {
  strings: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.user.isLoggedIn,
    user: state.user.user,
  };
};

export default connect(mapStateToProps)(MobileNavMenu);
