import React, { useEffect } from "react";
import MobileNavMenu from "./sub-components/MobileNavMenu";
import MobileWidgets from "./sub-components/MobileWidgets";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const MobileMenu = ({ logo, loading }) => {
  useEffect(() => {
    const offCanvasNav = document.querySelector("#offcanvas-navigation");
    const offCanvasNavSubMenu = offCanvasNav.querySelectorAll(".sub-menu");
    const anchorLinks = offCanvasNav.querySelectorAll("a");

    for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
      offCanvasNavSubMenu[i].insertAdjacentHTML(
        "beforebegin",
        "<span class='menu-expand'><i></i></span>"
      );
    }

    const menuExpand = offCanvasNav.querySelectorAll(".menu-expand");
    const numMenuExpand = menuExpand.length;

    for (let i = 0; i < numMenuExpand; i++) {
      menuExpand[i].addEventListener("click", (e) => {
        sideMenuExpand(e);
      });
    }

    for (let i = 0; i < anchorLinks.length; i++) {
      anchorLinks[i].addEventListener("click", () => {
        closeMobileMenu();
      });
    }
  });

  const sideMenuExpand = (e) => {
    e.currentTarget.parentElement.classList.toggle("active");
  };

  const closeMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.remove("active");
  };
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <div className="offcanvas-mobile-menu" id="offcanvas-mobile-menu">
      <button
        className="offcanvas-menu-close"
        id="mobile-menu-close-trigger"
        onClick={() => closeMobileMenu()}
      >
        <i className="pe-7s-close"></i>
      </button>
      <div className="offcanvas-wrapper">
        <div className="offcanvas-inner-content">
          <div className="mb-3">
            {!loading && logo && (
              <img
                src={logo.image}
                style={{ height: 50, width: "auto" }}
                alt="logo"
              />
            )}
          </div>

          {user && <h4>{"Hello, " + user.user.firstName}</h4>}

          {/* mobile search */}
          {/*}<MobileMenuSearch />*/}

          {/* mobile nav menu */}
          <MobileNavMenu />

          {/* mobile language and currency */}
          {/*}  <MobileLangCurChange />*/}

          {/* mobile widgets */}
          <MobileWidgets />
        </div>
      </div>
    </div>
  );
};

MobileMenu.propTypes = {
  logo: PropTypes.object,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    logo: state.logo,
    loading: state.misc.loading,
  };
};

export default connect(mapStateToProps)(MobileMenu);
