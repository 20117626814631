import { CONFIG_SUCCESS } from "../types/config";
import { SHOW_ERROR, SET_LOADING, STOP_LOADING } from "../types/misc";
import axios from "axios";

export const fetchConfigs = () => async (dispatch) => {
  dispatch({ type: SET_LOADING });
  try {
    const resp = await axios.get(process.env.REACT_APP_BACKEND_URL + `/config`);
    dispatch({ type: STOP_LOADING });
    dispatch({ type: CONFIG_SUCCESS, payload: resp.data.configuration });
  } catch (error) {
    console.log(error);
    dispatch({ type: SHOW_ERROR, payload: error.response.data.error });
  }
};
