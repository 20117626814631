import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getDiscountPrice } from "../../../helpers/product";

const MenuCart = ({ cartData, currency, deleteFromCart }) => {
  let cartTotalPrice = 0;
  const { addToast } = useToasts();
  return (
    <div className="shopping-cart-content">
      {cartData && cartData.length > 0 ? (
        <Fragment>
          <ul>
            {cartData.map((single, key) => {
              let productPrice;
              if (single.selectedProductColor) {
                let variationData = single.variation.filter(
                  (v) => v.color === single.selectedProductColor
                )[0];
                productPrice = variationData.mrp
                  ? variationData.mrp
                  : single.mrp;
              } else {
                productPrice = single.mrp;
              }
              let discountedPrice = getDiscountPrice(
                productPrice,
                single.discount
              );
              if (single.offerEnd && new Date() > new Date(single.offerEnd))
                discountedPrice = null;
              const finalProductPrice = (
                productPrice * currency.currencyRate
              ).toFixed(2);
              const finalDiscountedPrice = (
                discountedPrice * currency.currencyRate
              ).toFixed(2);

              discountedPrice != null
                ? (cartTotalPrice += finalDiscountedPrice * single.quantity)
                : (cartTotalPrice += finalProductPrice * single.quantity);

              return (
                <li className="single-shopping-cart" key={key}>
                  <div className="shopping-cart-img">
                    <Link
                      to={process.env.PUBLIC_URL + "/product/" + single._id}
                    >
                      <img
                        alt=""
                        src={
                          process.env.PUBLIC_URL + single.image
                            ? single.image[0]
                            : single.variation.filter(
                                (v) => v.color === single.selectedProductColor
                              )[0].image[0]
                        }
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div className="shopping-cart-title">
                    <h4>
                      <Link
                        to={process.env.PUBLIC_URL + "/product/" + single._id}
                      >
                        {" "}
                        {single.name}{" "}
                      </Link>
                    </h4>
                    <h6>Qty: {single.quantity}</h6>
                    <span>
                      {discountedPrice !== null
                        ? currency.currencySymbol + finalDiscountedPrice
                        : currency.currencySymbol + finalProductPrice}
                    </span>
                    {single.selectedProductColor ||
                    single.selectedProductSize ? (
                      <div className="cart-item-variation">
                        <span>
                          Color:{" "}
                          <div
                            style={{
                              backgroundColor: single.selectedProductColor,
                              borderRadius: "50%",
                              height: 20,
                              width: 20,
                            }}
                          />
                        </span>
                        {single.selectedProductSize && (
                          <span>Size: {single.selectedProductSize}</span>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="shopping-cart-delete">
                    <button onClick={() => deleteFromCart(single, addToast)}>
                      <i className="fa fa-times-circle" />
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="shopping-cart-total">
            <h4>
              Total :{" "}
              <span className="shop-total">
                {currency.currencySymbol + cartTotalPrice.toFixed(2)}
              </span>
            </h4>
          </div>
          <div className="shopping-cart-btn btn-hover text-center">
            <Link className="default-btn" to={process.env.PUBLIC_URL + "/cart"}>
              view cart
            </Link>
            <Link
              className="default-btn"
              to={process.env.PUBLIC_URL + "/checkout"}
            >
              checkout
            </Link>
          </div>
        </Fragment>
      ) : (
        <p className="text-center">No items added to cart</p>
      )}
    </div>
  );
};

MenuCart.propTypes = {
  cartData: PropTypes.array,
  currency: PropTypes.object,
  deleteFromCart: PropTypes.func,
};

export default MenuCart;
