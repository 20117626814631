import { LOGO_SUCCESS } from "../types/logo";
import { SHOW_ERROR, SET_LOADING, STOP_LOADING } from "../types/misc";
import axios from "axios";

const getLogo = async () => {
  let flag = false;
  let resp = await axios.get(process.env.REACT_APP_BACKEND_URL + "/logo");

  if (resp.data.message) {
    let response;
    if (!resp.data.logo || !resp.data.logo.image) {
      flag = true;
      let form = new FormData();
      let url = process.env.PUBLIC_URL + "/assets/img/logo/jewells.png";
      let res = await fetch(url);
      let blob = await res.blob();
      let file = await new File([blob], "dot.png", blob);

      form.set("image", file);
      form.set("name", "Template");
      response = await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/logo",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }
  }
  if (flag) {
    resp = await axios.get(process.env.REACT_APP_BACKEND_URL + "/logo");
    return resp;
  } else return resp;
};

export const fetchLogo = () => async (dispatch) => {
  dispatch({ type: SET_LOADING });
  try {
    const resp = await getLogo();
    dispatch({ type: STOP_LOADING });
    dispatch({ type: LOGO_SUCCESS, payload: resp.data.logo });
  } catch (error) {
    console.log(error);
    dispatch({ type: SHOW_ERROR, payload: "error" });
  }
};
