import React from "react";

const MobileWidgets = () => {
  return (
    <div className="offcanvas-widget-area">
      <div className="off-canvas-contact-widget">
        <div className="header-contact-info">
          <ul className="header-contact-info__list">
            <li>
              <i class="fa fa-whatsapp contact-"></i>{" "}
              {/* <a
                href="https://api.whatsapp.com/send?phone=+919315079557"
                class=""
                target="_blank"
                rel="noopener noreferrer"
              > */}
                +91 7877637947
              {/* </a> */}
            </li>
            <li>
              <i className="fa fa-envelope"></i>{" "}
              thehelioweb@gmail.com
            </li>
          </ul>
        </div>
      </div>
      {/*Off Canvas Widget Social Start*/}
      {/* <div className="off-canvas-widget-social">
        <a href="https://www.facebook.com/IamSportsamI/">
          <i className="fa fa-facebook" />
        </a>

        <a href="https://linkedin.com/in/sports-ami">
          <i className="fab fa-linkedin-in" />
        </a>

        <a href="https://www.youtube.com/channel/UCyxHiWCH2pYf9hTuMcSV59Q">
          <i className="fab fa-youtube" />
        </a>

        <a href="https://twitter.com/IAmSportsAmi">
          <i className="fa fa-twitter" />
        </a>
      </div> */}
      {/*Off Canvas Widget Social End*/}
    </div>
  );
};

export default MobileWidgets;
