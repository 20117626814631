import { CONFIG_SUCCESS, CONFIG_FAIL, RESET_CONFIG } from "../types/config";

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CONFIG_SUCCESS:
      return payload;

    case CONFIG_FAIL:
      return state;

    default:
      return state;
  }
}
