import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
} from "../types/user";
import {
  SET_MESSAGE,
  SHOW_ERROR,
  SET_LOADING,
  STOP_LOADING,
} from "../types/misc";
import axios from "axios";

export const signUp =
  (firstName, lastName, email, password, confirmPassword, mobile) =>
  async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const resp = await axios.post(
        process.env.REACT_APP_BACKEND_URL + `/customer/register`,
        { firstName, lastName, email, password, confirmPassword, mobile }
      );
      dispatch({ type: STOP_LOADING });
      if (resp.data.error) {
        await dispatch({ type: REGISTER_FAIL });
        await dispatch({ type: SHOW_ERROR, payload: resp.data.error });
        return false;
      } else {
        await dispatch({ type: REGISTER_SUCCESS });
        await dispatch({ type: SET_MESSAGE, payload: resp.data.message });
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

export const signIn = (email, password) => async (dispatch) => {
  dispatch({ type: SET_LOADING });
  try {
    const resp = await axios.post(
      process.env.REACT_APP_BACKEND_URL + `/customer/login`,
      { email, password }
    );
    dispatch({ type: STOP_LOADING });
    if (resp.data.error || resp.data.info) {
      dispatch({ type: LOGIN_FAIL });
      dispatch({ type: SHOW_ERROR, payload: resp.data.error });
    } else {
      if (resp.data.token) {
        localStorage.setItem("user", JSON.stringify(resp.data));
      }

      await dispatch({ type: LOGIN_SUCCESS, payload: resp.data });
      await dispatch({ type: SET_MESSAGE, payload: resp.data.message });
    }
    return resp;
  } catch (error) {
    console.log(error);
  }
};

export const logout = () => async (dispatch) => {
  try {
    await localStorage.removeItem("user");
    await dispatch({ type: LOGOUT });
  } catch (e) {
    console.log(e);
  }
};

export const updateInfo = (userId, data) => async (dispatch) => {
  //await localStorage.removeItem('redux_localstorage_simple');
  dispatch({ type: SET_LOADING });
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    const resp = await axios.put(
      process.env.REACT_APP_BACKEND_URL + `/customer/detail/${userId}`,
      data,
      {
        headers: { Authorization: `Bearer ${user.token}` },
      }
    );
    dispatch({ type: STOP_LOADING });
    if (resp.data.error) {
      dispatch({ type: UPDATE_FAIL });
      dispatch({ type: SHOW_ERROR, payload: resp.data.error });
    } else {
      const updateData = {
        token: user.token,
        user: resp.data.user,
      };
      await localStorage.setItem("user", JSON.stringify(updateData));
      await dispatch({ type: UPDATE_SUCCESS, payload: resp.data });
      await dispatch({ type: SET_MESSAGE, payload: resp.data.message });
    }
  } catch (error) {
    console.log(error);
  }
};
