import { BANNER_SUCCESS } from "../types/banner";
import { SHOW_ERROR, SET_LOADING, STOP_LOADING } from "../types/misc";
import axios from "axios";

const getBanners = async () => {
  let flag = false;
  let resp = await axios.get(process.env.REACT_APP_BACKEND_URL + "/banner");
  if (resp.data.message) {
    let response;
    if (!resp.data.banners || resp.data.banners.heroSlider.length === 0) {
      flag = true;
      let form = new FormData();
      let url =
        process.env.PUBLIC_URL + "/assets/img/slider/updates/slider29-1.png";
      let res = await fetch(url);
      let blob = await res.blob();
      let file = await new File([blob], "dot.png", blob);

      form.set("image", file);
      form.set("type", "Add");
      response = await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/banner/heroslide",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }
    if (
      !resp.data.banners ||
      !resp.data.banners.bannerOne ||
      !resp.data.banners.bannerOne.left
    ) {
      flag = true;
      let form = new FormData();
      let url =
        process.env.PUBLIC_URL + "/assets/img/banner/updates/banner-37.jpg";
      let res = await fetch(url);
      let blob = await res.blob();
      let file = await new File([blob], "dot.png", blob);

      form.set("image", file);
      form.set("type", "left");
      response = await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/banner/bannerone",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }

    if (
      !resp.data.banners ||
      !resp.data.banners.bannerOne ||
      !resp.data.banners.bannerOne.right
    ) {
      flag = true;
      let form = new FormData();
      let url =
        process.env.PUBLIC_URL + "/assets/img/banner/updates/banner-38.jpg";
      let res = await fetch(url);
      let blob = await res.blob();
      let file = await new File([blob], "dot.png", blob);

      form.set("image", file);
      form.set("type", "right");

      response = await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/banner/bannerone",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }
    if (
      !resp.data.banners ||
      !resp.data.banners.bannerTwo ||
      !resp.data.banners.bannerTwo.left ||
      !resp.data.banners.bannerTwo.left.image
    ) {
      flag = true;
      let form = new FormData();
      let url =
        process.env.PUBLIC_URL + "/assets/img/banner/updates/banner-39.jpg";
      let res = await fetch(url);
      let blob = await res.blob();
      let file = await new File([blob], "dot.png", blob);

      form.set("image", file);
      form.set("type", "left");

      response = await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/banner/bannertwo",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }
    if (
      !resp.data.banners ||
      !resp.data.banners.bannerTwo ||
      !resp.data.banners.bannerTwo.right ||
      !resp.data.banners.bannerTwo.right.image
    ) {
      flag = true;
      let form = new FormData();
      let url =
        process.env.PUBLIC_URL + "/assets/img/banner/updates/banner-40.jpg";
      let res = await fetch(url);
      let blob = await res.blob();
      let file = await new File([blob], "dot.png", blob);

      form.set("image", file);
      form.set("type", "right");

      response = await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/banner/bannertwo",
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }
  }

  if (flag) {
    resp = await axios.get(process.env.REACT_APP_BACKEND_URL + "/banner");
    return resp;
  } else {
    return resp;
  }
};

export const fetchBanners = () => async (dispatch) => {
  dispatch({ type: SET_LOADING });
  try {
    const resp = await getBanners();
    dispatch({ type: STOP_LOADING });
    dispatch({ type: BANNER_SUCCESS, payload: resp.data.banners });
  } catch (error) {
    console.log(error);
    dispatch({ type: SHOW_ERROR, payload: "error" });
  }
};
