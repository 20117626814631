import { THEME_SUCCESS } from "../types/theme";
import { SHOW_ERROR, SET_LOADING, STOP_LOADING } from "../types/misc";
import axios from "axios";

const getTheme = async () => {
  let flag = false;
  let resp = await axios.get(process.env.REACT_APP_BACKEND_URL + "/theme");

  if (resp.data.message) {
    let response;
    if (!resp.data.theme) {
      flag = true;

      response = await axios.put(process.env.REACT_APP_BACKEND_URL + "/theme", {
        primary: "#ff7e58",
      });
    }
  }
  if (flag) {
    resp = await axios.get(process.env.REACT_APP_BACKEND_URL + "/theme");
    return resp;
  } else return resp;
};

export const fetchTheme = () => async (dispatch) => {
  dispatch({ type: SET_LOADING });
  try {
    const resp = await getTheme();
    dispatch({ type: STOP_LOADING });
    dispatch({ type: THEME_SUCCESS, payload: resp.data.theme });
  } catch (error) {
    console.log(error);
    dispatch({ type: SHOW_ERROR, payload: "error" });
  }
};
