import { BANNER_SUCCESS, BANNER_FAIL, RESET_BANNER } from "../types/banner";

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case BANNER_SUCCESS:
      return payload;

    case BANNER_FAIL:
      return state;

    default:
      return state;
  }
}
