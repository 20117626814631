import React from "react";
import {Route,Redirect} from "react-router-dom";

const isAuthenticated=()=>{
  const user = JSON.parse(localStorage.getItem("user"));
  if(user){
    return true;
  }
  return false;
}



const UserRoute=({component:Component,...rest})=>(
  <Route {...rest} render={props=>isAuthenticated()?(
    <Component {...props}/>
  ):(
    <Redirect to={{pathname:"/login-register",state:{from:props.location,key:"login"}}}/>
  )}/>
)

export default UserRoute;
