import {
  PRODUCTS_SUCCESS,
  PRODUCTS_FAIL,
  RESET_PRODUCTS,
} from "../types/product";
import { SHOW_ERROR, SET_LOADING, STOP_LOADING } from "../types/misc";

import axios from "axios";

export const getArrivals = (page, limit) => async (dispatch) => {
  dispatch({ type: SET_LOADING });
  try {
    const resp = await axios.get(
      process.env.REACT_APP_BACKEND_URL +
        `/product/arrival/search?page=${page}&limit=${limit}`
    );
    dispatch({ type: STOP_LOADING });
    if (resp.data.error) {
      dispatch({ type: PRODUCTS_FAIL });
      dispatch({ type: SHOW_ERROR, payload: resp.data.error });
    } else {
      //console.log(resp.data)
      dispatch({ type: PRODUCTS_SUCCESS, payload: resp.data.products });
    }
  } catch (error) {
    console.log(error);
  }
};

export const getSellers = (page, limit) => async (dispatch) => {
  dispatch({ type: SET_LOADING });
  try {
    const resp = await axios.get(
      process.env.REACT_APP_BACKEND_URL +
        `/product/seller/search?page=${page}&limit=${limit}`
    );
    dispatch({ type: STOP_LOADING });
    if (resp.data.error) {
      dispatch({ type: PRODUCTS_FAIL });
      dispatch({ type: SHOW_ERROR, payload: resp.data.error });
    } else {
      //console.log(resp.data)
      dispatch({ type: PRODUCTS_SUCCESS, payload: resp.data.products });
    }
  } catch (error) {
    console.log(error);
  }
};

export const getSales = (page, limit) => async (dispatch) => {
  let user = JSON.parse(localStorage.getItem("user"));
  let userType = user ? user.user.userType : "Player";
  dispatch({ type: SET_LOADING });
  try {
    const resp = await axios.get(
      process.env.REACT_APP_BACKEND_URL +
        `/product/sales/search?page=${page}&limit=${limit}&userType=${userType}`
    );
    dispatch({ type: STOP_LOADING });
    if (resp.data.error) {
      dispatch({ type: PRODUCTS_FAIL });
      dispatch({ type: SHOW_ERROR, payload: resp.data.error });
    } else {
      //console.log(resp.data)
      dispatch({ type: PRODUCTS_SUCCESS, payload: resp.data.products });
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchProducts = () => async (dispatch) => {
  dispatch({ type: SET_LOADING });
  try {
    console.log(process.env.REACT_APP_BACKEND_URL);
    const resp = await axios.get(
      process.env.REACT_APP_BACKEND_URL + `/product/all`
    );
    console.log("products", resp.data);
    dispatch({ type: STOP_LOADING });
    dispatch({ type: PRODUCTS_SUCCESS, payload: resp.data.products });
  } catch (error) {
    console.log(error);
    dispatch({ type: SHOW_ERROR, payload: error.response.data.error });
  }
};

export const getFilteredProducts =
  (page, limit, category, upperLimit, lowerLimit) => async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const resp = await axios.post(
        process.env.REACT_APP_BACKEND_URL +
          `/product/filter/search?page=${page}&limit=${limit}`,
        { category, upperLimit, lowerLimit }
      );
      dispatch({ type: STOP_LOADING });
      if (resp.data.error) {
        dispatch({ type: PRODUCTS_FAIL });
        dispatch({ type: SHOW_ERROR, payload: resp.data.error });
      } else {
        //console.log(resp.data)
        dispatch({ type: PRODUCTS_SUCCESS, payload: resp.data.products });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const getSearchedProducts =
  (page, limit, keyword) => async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const resp = await axios.get(
        process.env.REACT_APP_BACKEND_URL +
          `/product/search?page=${page}&limit=${limit}&keyword=${keyword}`
      );
      dispatch({ type: STOP_LOADING });
      if (resp.data.error) {
        dispatch({ type: PRODUCTS_FAIL });
        dispatch({ type: SHOW_ERROR, payload: resp.data.error });
      } else {
        //console.log(resp.data)
        dispatch({ type: PRODUCTS_SUCCESS, payload: resp.data.products });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const clearProducts = () => ({
  type: RESET_PRODUCTS,
});
