import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { connect } from "react-redux";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import Axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
import { getDiscountPrice } from "../../helpers/product";

const MyOrders = ({ location, currency, loading, logo }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [pastOrders, setPastOrders] = useState([]);
  const { pathname } = location;
  const fetchProducts = async () => {
    const resp = await Axios.get(
      process.env.REACT_APP_BACKEND_URL + `/order/customer/${user.user._id}`,
      {
        headers: { Authorization: `Bearer ${user.token}` },
      }
    );
    setPastOrders(resp.data.orders);
  };
  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <MetaTags>
        <title>{!loading && logo && logo.name} | My Orders</title>
        <meta
          name="description"
          content="Wishlist page of flone react minimalist eCommerce template."
        />
      </MetaTags>

      <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
        Past Orders
      </BreadcrumbsItem>

      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb />
        <div className="cart-main-area pt-90 pb-100">
          <div className="container">
            {pastOrders && pastOrders.length >= 1 ? (
              <Fragment>
                <h3 className="cart-page-title">Your Past Order items</h3>

                {pastOrders.map((order, key) => {
                  return (
                    <div className="row mt-5">
                      <div className="col-12">
                        <div className="table-content table-responsive cart-table-content">
                          <table>
                            <thead>
                              <tr>
                                <th>Image</th>
                                <th>Product Name</th>
                                <th>Purchase Date</th>
                                <th>Order Status</th>
                                <th>Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {order.items.map((pastOrder, key) => {
                                let product = pastOrder.selectedProductColor
                                  ? pastOrder.product.variation.filter(
                                      (v) =>
                                        v.color ===
                                        pastOrder.selectedProductColor
                                    )[0]
                                  : pastOrder.product;
                                const discountedPrice = getDiscountPrice(
                                  pastOrder.price,
                                  pastOrder.discount
                                );
                                const finalProductPrice = (
                                  pastOrder.price * currency.currencyRate
                                ).toFixed(2);
                                const finalDiscountedPrice = (
                                  discountedPrice * currency.currencyRate
                                ).toFixed(2);

                                return (
                                  <tr key={key}>
                                    <td className="product-thumbnail">
                                      <img
                                        className="img-fluid"
                                        src={product.image[0]}
                                        alt=""
                                        width={"60px"}
                                        height="auto"
                                      />
                                      <div
                                        style={{
                                          backgroundColor:
                                            pastOrder.selectedProductColor,
                                          borderRadius: "50%",
                                          height: 20,
                                          width: 20,
                                          alignSelf: "center",
                                        }}
                                      ></div>
                                    </td>

                                    <td className="product-name text-center">
                                      <Link
                                        to={
                                          process.env.PUBLIC_URL +
                                          "/product/" +
                                          pastOrder.product._id
                                        }
                                      >
                                        {pastOrder.product.name}
                                        <br />
                                        {pastOrder.selectedProductSize && (
                                          <>
                                            {"(" +
                                              pastOrder.selectedProductSize +
                                              ")"}
                                            <br />
                                          </>
                                        )}
                                      </Link>
                                    </td>
                                    <td className="text-center">
                                      {new Date(
                                        order.createdAt
                                      ).toLocaleString()}
                                    </td>
                                    <td className="product-name text-center">
                                      <Tooltip
                                        title={
                                          "Last Updated At: " +
                                          new Date(
                                            order.updatedAt
                                          ).toLocaleString()
                                        }
                                        arrow
                                      >
                                        <span
                                          style={{
                                            fontSize: 13,
                                            fontWeight: 500,
                                            lineHeight: 1,

                                            marginBottom: 10,
                                            padding: "10px 30px",
                                            color: "#fff",
                                            borderRadius: 3,
                                            backgroundColor: `${
                                              order.status === "Created"
                                                ? "#4aa3ff"
                                                : order.status === "Processing"
                                                ? "#fcb92c"
                                                : order.status === "Shipped"
                                                ? "#e83e8c"
                                                : "#1cbb8c"
                                            }`,
                                            cursor: "pointer",
                                          }}
                                        >
                                          {order.status}
                                        </span>
                                      </Tooltip>
                                    </td>

                                    <td className="product-name product-price-cart">
                                      Qty x Unit Price -{" "}
                                      <span className="amount">
                                        {pastOrder.quantity} x{" "}
                                        {currency.currencySymbol +
                                          pastOrder.price}
                                      </span>
                                      <br />
                                      {pastOrder.discount && (
                                        <>
                                          Discount - {pastOrder.discount + "%"}
                                          <br />
                                        </>
                                      )}
                                      SubTotal -{" "}
                                      <span className="amount">
                                        {discountedPrice !== null
                                          ? currency.currencySymbol +
                                            (
                                              finalDiscountedPrice *
                                              pastOrder.quantity
                                            ).toFixed(2)
                                          : currency.currencySymbol +
                                            (
                                              finalProductPrice *
                                              pastOrder.quantity
                                            ).toFixed(2)}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr style={{ backgroundColor: "#E8E8FD" }}>
                                <td></td>
                                <td> </td>
                                <td></td>
                                <td></td>
                                <td
                                  style={{
                                    textAlign: "left",
                                  }}
                                >
                                  {order.coupon && (
                                    <>
                                      {" "}
                                      Coupon
                                      {"(" +
                                        order.coupon.couponCode +
                                        ") - " +
                                        order.coupon.discount +
                                        "%"}
                                      <hr style={{ marginRight: "1rem" }} />
                                    </>
                                  )}
                                  Total Cost -{" "}
                                  {currency.currencySymbol +
                                    order.totalAmount.toFixed(2)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Fragment>
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  <div className="item-empty-area text-center">
                    <div className="item-empty-area__icon mb-30">
                      <i className="pe-7s-like"></i>
                    </div>
                    <div className="item-empty-area__text">
                      No past orders <br />{" "}
                      <Link to={process.env.PUBLIC_URL + "/shop"}>
                        Continue shopping
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

MyOrders.propTypes = {
  currency: PropTypes.object,
  location: PropTypes.object,
  loading: PropTypes.bool,
  logo: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    currency: state.currencyData,
    loading: state.misc.loading,
    logo: state.logo,
  };
};

export default connect(mapStateToProps)(MyOrders);
