import PropTypes from "prop-types";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import MenuCart from "./sub-components/MenuCart";
import { deleteFromCart } from "../../redux/actions/cartActions";
import { logout } from "../../redux/actions/userActions";
import { getCartItems } from "../../helpers/product";

const IconGroup = ({
  currency,
  cartData,
  wishlistData,
  compareData,
  dispatch,
  deleteFromCart,
  iconWhiteClass,
  isLoggedIn,
  location,
  logout,
  user,
}) => {
  const history = useHistory();
  //console.log("user",user);
  const handleClick = (e) => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };

  return (
    <div
      className={`header-right-wrap ${iconWhiteClass ? iconWhiteClass : ""}`}
    >
      {/*########## Search ##########*/}
      {/*}  <div className='same-style header-search d-none d-lg-block'>
        <button className='search-active' onClick={(e) => handleClick(e)}>
          <i className='pe-7s-search' />
        </button>
        <div className='search-content'>
          <form action='#'>
            <input type='text' placeholder='Search' />
            <button className='button-search'>
              <i className='pe-7s-search' />
            </button>
          </form>
        </div>
      </div>*/}

      {/*########## Account Details ##########*/}
      <div className="same-style account-setting d-none d-lg-block">
        <button
          className="account-setting-active"
          onClick={(e) => handleClick(e)}
        >
          <span
            style={{
              display: "flex",
            }}
          >
            <i className="pe-7s-user-female" />
            <span>
              {isLoggedIn === true && (
                <p
                  className="ml-2 mt-1"
                  style={{ fontSize: "0.65rem", lineHeight: "12px" }}
                >
                  Hello, {user.user.firstName}
                </p>
              )}
            </span>
          </span>
        </button>
        <div className="account-dropdown">
          <ul>
            {isLoggedIn === false ? (
              <>
                <li>
                  <Link
                    to={{
                      pathname: process.env.PUBLIC_URL + "/login-register",
                      state: { key: "login" },
                    }}
                  >
                    Login
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: process.env.PUBLIC_URL + "/login-register",
                      state: { key: "register" },
                    }}
                  >
                    Register
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/my-account"}>
                    my account
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/my-orders"}>
                    my orders
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={async () => {
                      await logout();
                      await history.push("/");
                      await window.location.reload();
                    }}
                  >
                    Logout
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>

      {/*########## Wishlist ##########*/}
      <div className="same-style header-wishlist">
        <Link to={process.env.PUBLIC_URL + "/wishlist"}>
          <i className="pe-7s-like" />
          <span className="count-style">
            {wishlistData && wishlistData.length ? wishlistData.length : 0}
          </span>
        </Link>
      </div>

      {/*########## Cart ##########*/}
      <div className="same-style cart-wrap d-none d-lg-block">
        <button className="icon-cart" onClick={(e) => handleClick(e)}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cartData && cartData.length ? cartData.length : 0}
          </span>
        </button>
        {/* menu cart */}
        <MenuCart
          cartData={cartData}
          currency={currency}
          deleteFromCart={deleteFromCart}
        />
      </div>
      <div className="same-style cart-wrap d-block d-lg-none">
        <Link className="icon-cart" to={process.env.PUBLIC_URL + "/cart"}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cartData && cartData.length ? cartData.length : 0}
          </span>
        </Link>
      </div>
      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu" />
        </button>
      </div>
    </div>
  );
};

IconGroup.propTypes = {
  cartData: PropTypes.array,
  compareData: PropTypes.array,
  currency: PropTypes.object,
  iconWhiteClass: PropTypes.string,
  deleteFromCart: PropTypes.func,
  logout: PropTypes.func,
  wishlistData: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    currency: state.currencyData,
    cartData: getCartItems(state.cartData, state.productData.products),
    wishlistData: getCartItems(state.wishlistData, state.productData.products),
    compareData: state.compareData,
    isLoggedIn: state.user.isLoggedIn,
    user: state.user.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteFromCart: (item, addToast) => {
      dispatch(deleteFromCart(item, addToast));
    },
    logout: () => {
      dispatch(logout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IconGroup);
