import { THEME_SUCCESS, THEME_FAIL, RESET_THEME } from "../types/theme";

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case THEME_SUCCESS:
      return payload;

    case THEME_FAIL:
      return state;

    default:
      return state;
  }
}
