import { LOGO_SUCCESS, LOGO_FAIL, RESET_LOGO } from "../types/logo";

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGO_SUCCESS:
      return payload;

    case LOGO_FAIL:
      return state;

    default:
      return state;
  }
}
